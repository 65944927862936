<template>

  <div class="parent-modal">
    <div class="modal myModal fade" id="edit-category" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content category-content">
          <div class="modal-header category-header align-items-center">
            <h3 class="modal-title">Edit Category</h3>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close">
              <span aria-hidden="true">
                <img src="../../../assets/icons/x.svg" alt="">
              </span>
            </button>
          </div>
          <div class="modal-body category-body">
            <form @submit.prevent="onSubmit" class="modal-form">
              <label>Name</label><br>
              <input v-model="currentCategory.name" class="form-input cashback-input mb-3" placeholder="Enter a name">
              <div class="d-none">
                <label>Move to the Category</label>
                <select class="form-control long-form-control modal-select mb-5">
                  <option v-for="category in listCategory" :key="category._id">{{ category._id }}</option>
                </select>
              </div>
              <div class="col-12">
                <p class="news-description margin-bottom-20">You can upload JPG or PNG, the minimum resolution is<br>
                  540*405 px, the size is not more than 3 MB.</p>
                <button type="button" class="upload-photo margin-bottom-30" @click="selectPhoto">+ Upload
                  photo</button><br>
                <input class="d-none" type="file" name="" ref="uploadPhoto" @change="uploadPhoto($event)"
                  accept="image/*">
                <div class="image-preview position-relative" v-if="img">
                  <img v-if="newImage" :src="imagePreview" alt="" class="img-new w-100 d-block"
                    accept="image/png, image/jpeg">
                  <img v-else :src="img" alt="" class="w-100 img-new d-block" accept="image/png, image/jpeg">
                  <button type="button" class="deleteImage" @click="clearInput">
                    <img src="../../../assets/icons/deleteClient.svg">
                  </button>
                </div>

              </div>
              <div class="d-flex justify-content-end">
                <button @click.prevent="onSubmit(currentCategory._id)" class="save">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "EditCategory",
  props: ['select_category', 'getCategories', 'listCategory'],
  data() {
    return {
      currentCategory: '',
      img: "",
      newImage: false
    }
  },
  computed: {
    imagePreview() {
      if (this.img) {
        return URL.createObjectURL(this.img)
      }
      return null
    }
  },
  methods: {
    selectPhoto() {
      this.$refs.uploadPhoto.click();
    },
    uploadPhoto(event) {
      var valid = ["image/png", "image/jpg", "image/jpeg"];

      if (event.target.files[0] && event.target.files[0].size > 3000000) {
        this.$warningAlert('Image size exceed 3 mb');
      } else if (event.target.files[0] && !valid.includes(event.target.files[0].type)) {
        this.$warningAlert('Image type can be jpg or png');
      } else {
        this.newImage = true
        this.img = event.target.files[0]
      }
      this.$refs.uploadPhoto.value = ""
    },
    makeImg(name) {
      return this.img(name)
    },

    onSubmit(id) {

      const form = new FormData;
      if (this.img) {
        form.append('img', this.img)
      }
      form.append('name', this.currentCategory.name);
      form.append('type', 'product');

      this.axios.put(this.url('updateCategory', id), form)
        .then(() => {
          this.currentCategory = '',
            this.img = "",
            this.newImage = false
          this.getCategories()
          this.$informationAlert('Category changed')
          $('#edit-category').modal("hide")
        })
    }
  },
  watch: {
    select_category(newCat) {
      this.currentCategory = Object.assign({}, newCat);
      this.img = newCat.img;
    }
  },
}
</script>

<style scoped>
.cashback-input {
  width: 100%;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.upload-photo {
  background: none;
  border: 1px solid #616CF5;
  border-radius: 5px;
  padding: 10px 25px;
  color: #616cf5;
}
</style>