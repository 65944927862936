<template>
  <div class="parent-modal">
    <div class="modal myModal fade" id="countQr-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content category-content">
          <div class="modal-header category-header align-items-center">
            <h3 class="modal-title">Add Category</h3>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close">
              <span aria-hidden="true">
                <img src="../../assets/icons/x.svg" alt="">
              </span>
            </button>
          </div>
          <div class="modal-body category-body">
            <form @submit.prevent="onSubmit" class="modal-form">
              <label>Count</label>
              <input class="form-input cashback-input mb-3" v-model="new_category.name" name="name" placeholder="Enter a count">
              <div class="d-flex justify-content-end">
                <button class="save">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <ShowQRPopup :qr-codes="qrCodes"></ShowQRPopup>
  </div>
</template>

<script>
import $ from 'jquery'
import ShowQRPopup from './ShowQRPopup.vue'; // Импортируем компонент

export default {
  name: "CountQrPopup",
  components: {
    ShowQRPopup // Регистрируем компонент
  },
  props: {
    catalogList: {
      type: Array,
      required: true
    },
    getCategories: {
      type: Function,
    }
  },
  data() {
    return {
      new_category: {
        name: '',
        type: 'product'
      },
      qrCodes: []
    }
  },
  methods: {
    onSubmit() {
      const nameProduct = localStorage.getItem('catalogName')
      const data = {
        "name": nameProduct,
        "count": this.new_category.name
      }
      console.log(nameProduct);
      console.log(this.new_category.name);
      this.axios.post('https://app.moore22.store/createQR', data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          this.qrCodes = response.data.object.map(item => item.QRCode);
          $('#showQRPopup').modal("show"); // Это можно удалить
        })
        .catch(error => {
          console.error("Error", error);
        });
      $('#countQr-popup').modal("hide");
      this.new_category.name = '1';
    }
  }
}
</script>

<style scoped>
.modal-select {
  font-size: 14px;
}
.category-content {
  border: 0;
  border-radius: 0;
  width: 562px;
}
.parent-modal {
  display: flex;
}
.category-body {
  padding: 0 50px;
  padding-bottom: 34px;
}
.category-body input {
  width: 100%;
}
.category-header {
  padding: 34px 50px;
}
</style>
