<template>
    <div class="modal fade" id="showQRPopup" tabindex="-1" role="dialog" aria-labelledby="showQRPopupLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="showQRPopupLabel">QR Codes</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-for="(qrCode, index) in qrCodes" :key="index" class="text-center mb-3">
              <img :src="qrCode" alt="QR Code" class="img-fluid">
            </div>
            <div v-if="qrCodes.length === 0" class="text-center">No QR Codes available.</div>
          </div>
          <button @click="generateDoc">Download All QR Images</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { saveAs } from 'file-saver';
  import htmlDocx from 'html-docx-js/dist/html-docx';
  export default {
    props: {
      qrCodes: {
        type: Array,
        required: true
      }
    },
    methods: {
      async generateDoc() {
          const imagesArr = this.qrCodes;
          const homeUrl = 'https://app.moore22.store/';
          const nameProduct = localStorage.getItem('catalogName')

          const imagePromises = imagesArr.map(image => {
              return new Promise((resolve, reject) => {
                  const img = new Image();
                  img.crossOrigin = 'Anonymous';
                  img.onload = () => {
                      const canvas = document.createElement('canvas');
                      canvas.width = img.width;
                      canvas.height = img.height;
                      const ctx = canvas.getContext('2d');
                      ctx.drawImage(img, 0, 0);
                      const dataURL = canvas.toDataURL('image/png');
                      resolve({ image, base64: dataURL });
                  };
                  img.onerror = () => reject(new Error(`Failed to load image: ${image}`));
                  img.src = homeUrl + image;
              });
          });

          try {
              const imageData = await Promise.all(imagePromises);

              let htmlContent = '';

              imageData.forEach((data, index) => {
                  htmlContent += `
                      <img src="${data.base64}" alt="Изображение ${index + 1}" width="400" height="400" style="width: 200px; height: auto;">
                  `;
              });

              const convertedDoc = htmlDocx.asBlob(htmlContent);

              let filename = nameProduct.replace(/\s/g, '_') + '.doc'
              saveAs(convertedDoc, filename);
          } catch (error) {
              console.error('Failed to generate document:', error);
          }
      }
    }
  }
  </script>
  
  <style scoped>
  /* Add your custom styles here */
  </style>
  