<template>
  <div class="loadingio-spinner-rolling-rfot3au6lb "><div class="ldio-05yy88kmhjet">
    <div></div>
  </div></div>
</template>

<script>
export default {
  name: "Spinner"
}
</script>

<style scoped>

@keyframes ldio-05yy88kmhjet {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-05yy88kmhjet div {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #616cf5;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-05yy88kmhjet div {
  animation: ldio-05yy88kmhjet 1s linear infinite;
  top: 100px;
  left: 50%;
}
.loadingio-spinner-rolling-rfot3au6lb {
  width:100%;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-05yy88kmhjet {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */

}
.ldio-05yy88kmhjet div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>


