<template>
  <div v-if="showEdit" class="modal fade right"  id="edit-product" tabindex="-1" role="dialog" aria-labelledby="edit-product" aria-hidden="true">
    <div class="modal-dialog modal-full-height myModal-dialog mr-0 mt-0 mb-0 mr-0 h-100" style="max-width:calc(100% - 250px)" role="document" >
      <div class="modal-content myModal-content h-100">
        <div class="modal-header justify-content-start align-items-center">
          <img @click="$emit('toggleShowEdit')" data-dismiss="modal" aria-label="Close" class="close mr-2" src="../../../assets/icons/xBlack.svg" alt="">
          <h3 class="modal-title">Edit</h3>
        </div>
        <div class="myModal-body">
          <div class="row">
            <div class="col-11 m-auto">
              <form  class="modal-form">
                <div class=" mb-3">
                  <div style="width:100%" class="mr-3">
                    <label class="product-label">Name</label>
                    <input required :class="{errorInput: validateName}" name="name"  v-model="name" style="width:100%" class="cashback-input">
                    <div v-if="validateName" class="fill-fields">Fill in the fields</div>
                  </div>
                  <div class="searchable-select" style="width:100%;">
                    <label class="product-label">Выберите комплект</label>
                    <input
                      :class="{errorInput: validateName}"
                      class="cashback-input"
                      v-model="value"
                      type="text"
                      placeholder="Введите название..."
                      @input="searchProducts"
                    >
                    <ul v-if="value.length">
                      <li v-for="p in searchResults" :key="p._id" @click="handleSelect(p)">
                        {{ p.name }}
                      </li>
                    </ul>
                    <span v-if="this.selectedValues.length" class="mb-1">Выбранные комплекты:</span>
                    <div class="selected" v-for="s in this.selectedValues" :key="s._id">
                      <span>{{ s.name }}</span>
                      <img @click="removeSet(s._id)" class="close" src="../../../assets/icons/xBlack.svg" alt="">
                    </div>
                  </div>
                </div>
                <div class="modal-btn d-flex">
                  <button @click.prevent="onSubmit" class="save">Save</button>
                  <button @click="$emit('toggleShowEdit')" type="button" class="cancel" data-dismiss="modal" aria-label="Close" >Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import $ from "jquery";

export default {
  name: "EditProduct",
  props: [
    'select_product',
    'getProducts',
    'listAllProducts',
    'showEdit'
  ],
  data() {
    return {
      name: this.select_product.name || '',
      value: '',
      validateName: false,
      searchResults: [],
      selectedValues: this.select_product.productSet || [],
    }
  },
  methods: {
    async searchProducts() {
        const result = await this.axios.get(this.url('getProducts'), {
          headers: { "x-client-url": this.currentCompanyCatalog },
          params: {
            "page": 1,
            "searchText": this.value,
          },
        })
        this.searchResults = result?.data?.objects;
    },
    handleSelect (p) {
      console.log('test', p)
      const foundItem = this.searchResults.find((item) => item?._id === p._id);
      console.log(foundItem)
      const itemExist = this.selectedValues.findIndex((item) => item?._id == foundItem?._id);
      if (itemExist < 0) {
        this.selectedValues.push(foundItem);
      }
      this.value = '';
    },
    removeSet(id) {
      this.selectedValues = this.selectedValues.filter((item) =>  item._id !== id);
    },
    limitedText(text) {
      if (text.length <= 100) {
        return text;
      } else {
        return text.slice(0, 100) + "...";
      }
    },
    async onSubmit() {
      if (this.name === ""){
        this.validateName = true
        return
      } else {
        this.validateName = false
      }
      await this.axios.put(this.url('updateProduct', this.select_product._id), {
        name: this.name,
        productSet: this.selectedValues,
      })
      await this.getProducts()
      $('#edit-product').modal("hide")
      this.$informationAlert('Changes are saved')
      this.$emit('toggleShowEdit');
    }
  },
}
</script>

<style scoped>
.modal.fade:not(.in).right .modal-dialog {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0, 0, 0);
}
.modal-header .close{
  margin: 0;
}
.fill-fields{
  color:#E94A4A;
  margin-top: 5px;

}
.modal-form input{
  width: 100%;
  font-size: 16px;
}
.modal-form label{
  margin-right: 10px;
  margin-bottom: 8px;
}
.selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchable-select {
  position: relative;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

ul {
  z-index: 10;
  position: absolute;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-top: 0;
  background-color: rgb(233, 231, 231);
  border-radius: 4px;
}

li {
  cursor: pointer;
  padding: 5px;
}
</style>